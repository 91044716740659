<template>
  <div class="auth">
    <h1>{{ $t("auth.registration") }}</h1>

    <form @submit="signUp" class="form" method="post">
      <div class="form-wrap">
        <input v-model="user.email" class="form-input" type="email" name="email" id="email" placeholder="E-Mail" />
      </div>

      <div class="row row-gutter-20">
        <div class="col-12">
          <div class="form-wrap">
            <input v-model="user.first_name" class="form-input" type="text" name="first_name" id="first_name" :placeholder="$t('global.firstname')">
          </div>
        </div>
        <div class="col-12">
          <div class="form-wrap">
            <input v-model="user.last_name" class="form-input" type="text" name="last_name" id="last_name" :placeholder="$t('global.lastname')">
          </div>
        </div>
      </div>

    <div class="row row-gutter-20">
      <div class="col-16">
      <div class="form-wrap">
        
        <input v-model="user.street_name" class="form-input" type="street_name" name="street_name" id="street_name" :placeholder="$t('global.street')"/>
      </div>
    </div>
    <div class="col-8">
      <div class="form-wrap">
        <input v-model="user.street_number" class="form-input" type="street_number" name="street_number" id="street_number" :placeholder="$t('global.housenumber')"/>
      </div>
    </div>
  </div>

  <div class="row row-gutter-20">
    <div class="col-8">
      <div class="form-wrap">
        <input v-model="user.zip_code" class="form-input" type="zip_code" name="zip_code" id="zip_code" :placeholder="$t('global.postalcode')"/>
      </div>
    </div>
    <div class="col-16">
      <div class="form-wrap">
        <input v-model="user.city" class="form-input" type="city" name="city" id="city" :placeholder="$t('global.city')"/>
      </div>
    </div>
  </div>

      <div class="form-wrap">
        <input v-model="user.password" class="form-input" type="password" name="password" id="password" :placeholder="$t('auth.password')" />
      </div>

      <p style="margin-bottom: 0; display: flex"></p>

      <div class="custom-checkbox-conainer">
        <input v-model="user.accept_privacy" type="checkbox" id="privacy" style="margin-right: 7px" />
        <label for="privacy" style="width: calc(100% - 35px)"
          >{{ $t("auth.acceptStart") }}
          <strong>
            <a :href="$t('link.privacy')" target="_blank" style="font-weight: 600; color: #444">{{ $t("auth.privacy") }}</a>
          </strong>
          {{ $t("auth.acceptEnd") }}</label
        >
      </div>

      <div class="custom-checkbox-conainer">
        <input v-model="user.accept_terms" type="checkbox" id="terms" style="margin-right: 7px" />
        <label for="terms" style="width: calc(100% - 35px)"
          >{{ $t("auth.acceptStart") }}
          <strong>
            <a :href="$t('link.agb')" target="_blank" style="font-weight: 600; color: #444">{{ $t("auth.agb") }}</a>
          </strong>
          {{ $t("auth.acceptEnd") }}</label
        >
      </div>

      <p style="text-align: right">
        <input type="submit" value="Jetzt Registrieren ›" class="button button-ci button-round button-100" />
      </p>

      <div class="auth-meta">
        <p>{{ $t("auth.account") }}</p>
        <p class="sign_up">
          <router-link to="/auth/sign_in" class="link">{{ $t("auth.loginButton") }}</router-link>
        </p>
      </div>
    </form>
  </div>
</template>

<style></style>

<script>
import axios from "axios";
import { Plugins } from "@capacitor/core";
const { Device } = Plugins;
import router from "../../router";

export default {
  name: "auth-sign-up",
  data() {
    return {
      errors: [],
      user: {
        email: "",
        password: "",
        referred_by: "",
        accept_privacy: false,
        accept_terms: false,
      },
      device: {},
      email_auth: true,
    };
  },
  methods: {
    check_cookies() {
      var cookie_name = "oohlemon-referral";
      var b = document.cookie.match("(^|;)\\s*" + cookie_name + "\\s*=\\s*([^;]+)");
      b = b ? b.pop() : "";
      if (b) {
        this.user.referred_by = b;
      }
    },
    signUp: function (e) {
      e.preventDefault();

      axios
        .post(
          process.env.VUE_APP_BASE_API + "/auth/sign_up",
          {
            email: this.user.email,
            password: this.user.password,
            first_name: this.user.first_name,
            last_name: this.user.last_name,
            street_name: this.user.street_name,
            street_number: this.user.street_number,
            zip_code: this.user.zip_code,
            city: this.user.city,
            referred_by: this.user.referred_by,
            accept_privacy: this.user.accept_privacy,
            accept_terms: this.user.accept_terms,
          },
          { headers: {} }
        )
        .then((response) => {
          console.log(response);
          this.$notify({
            group: "notification",
            type: "success",
            text: this.$t("auth.registrationInfo"),
          });
          this.$router.push("/auth/sign_in");
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
    },
    async get_device_info() {
      const info = await Device.getInfo();
      this.device = info;
      this.$store.commit("addDevice", info);
    },
  },
  mounted() {
    this.get_device_info();
    this.check_cookies();

    if (this.$store.getters.isLoggedIn) {
      router.push("/dashboard");
    }
  },
};
</script>
